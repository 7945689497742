<!--
 * @Descripttion: 添加清单弹框
 * @Author: liuxia
 * @Date: 2022-06-09 15:55:40
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-13 09:56:46
-->
<template>
  <div class="add-inventory scrollbar" ref="addInventory">
    <div class="search">
      <a-input-search
        style="margin-bottom: 8px"
        placeholder="请输入清单编码或名称"
        @search="onSearch"
      />
    </div>
    <a-spin v-if="loading" class="inventory-tree-loading" />
    <a-tree
      v-else
      :expandedKeys.sync="expandedKeys"
      :tree-data="treeList"
      class="inventory-tree"
      @select="onExpand"
      :replaceFields="{
        title: 'dbName',
        key: 'sequenceNbr',
        children: 'childrenList',
      }"
    >
      <template #title="dataRef">
        <div
          v-if="!dataRef.childrenList"
          class="tree-item"
          @dblclick="dbClickHandler(dataRef, $event)"
        >
          <span @click="showUnitTooltip = false">
            {{ dataRef.bdCode }} --- {{ dataRef.bdName }} --{{ dataRef.unit }}
          </span>
          <span class="selected" @click="selectBeforeHandler(dataRef, $event)"
            >选择</span
          >
        </div>
        <span v-else>{{ dataRef.bdName }}</span>
      </template>
    </a-tree>
    <a-empty v-if="!treeList.length" />
    <div class="unit-radio" v-if="showUnitTooltip" :style="unitRadioStyle">
      <div class="title">选择单位</div>
      <a-radio-group
        v-model="currentDataRef.unit"
        @change="unitChange(currentDataRef, $event)"
      >
        <a-radio
          v-for="(unit, index) in currentDataRef.unitList"
          :key="index"
          :value="unit"
        >
          {{ unit }}
        </a-radio>
      </a-radio-group>
      <a-button type="link" @click="selectHandler(currentDataRef)"
        >确定</a-button
      >
    </div>
  </div>
</template>

<script>
import { listByTree } from '@/api/costQuotaLibrary/costLibrary';
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'addInventory',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedKeys: [],
      searchValue: '',
      treeList: [],
      loading: true,
      showUnitTooltip: false,
      currentDataRef: null,
      unitRadioStyle: {},
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.showUnitTooltip = false;
      }
    },
  },
  created() {
    this.getTreeData();
  },
  methods: {
    onExpand(selectedKeys, { node }) {
      const key = selectedKeys[0];
      if (node.dataRef.childrenList && node.dataRef.childrenList.length) {
        // if ()
        if (!node.expanded) {
          this.expandedKeys.push(key);
        } else {
          const index = this.expandedKeys.indexOf(key);
          this.expandedKeys.splice(index, 1);
        }
      }
    },
    onSearch(value) {
      const treeData = this.filterTreeList(this.treeListCache, value);
      this.treeList = treeData;
      this.searchValue = value;
      if (!treeData.length) return;
      const keys = this.getFirstExpandedKey(treeData[0]);
      this.expandedKeys = keys;
    },
    filterTreeList(list, value = '') {
      const projectTree = _.cloneDeep(list);
      function treeLoop(treeData) {
        let children = [];
        let isSelect = false;
        for (let index in treeData) {
          const tree = treeData[index];
          const { bdCode, bdName } = tree;
          if (bdCode.indexOf(value) > -1 || bdName.indexOf(value) > -1) {
            children.push(tree);
            isSelect = true;
          } else {
            if (tree?.childrenList?.length) {
              let [subChild, isChildSelect] = treeLoop(tree.childrenList);
              if (isChildSelect) {
                isSelect = isChildSelect;
                tree.childrenList = subChild;
                children.push(tree);
              }
            }
          }
        }
        return [children, isSelect];
      }
      return treeLoop(projectTree)[0];
    },
    dbClickHandler(dataRef, e) {
      if (e.target.className.indexOf('selected') >= 0) return;
      this.selectBeforeHandler(dataRef, e);
    },
    selectBeforeHandler({ pos, dataRef }, e) {
      const treeInfo = this.$refs.addInventory.getBoundingClientRect();
      const { top, height } = e.target.getBoundingClientRect();
      this.unitRadioStyle = {
        top: `${top - treeInfo.top}px`,
      };
      if (!dataRef.unitList?.length) {
        const unit = dataRef.unit.split('/');
        dataRef.unitList = unit;
        dataRef.unit = unit[0];
      }
      this.currentDataRef = dataRef;
      if (dataRef.unitList.length > 1) {
        this.showUnitTooltip = true;
      } else {
        this.$emit('selectRow', dataRef);
      }
    },
    selectHandler(dataRef) {
      this.showUnitTooltip = false;
      this.$emit('selectRow', dataRef);
    },
    unitChange(dataRef, e) {
      dataRef.unit = e.target.value;
    },
    getTreeData() {
      listByTree().then(({ status, result }) => {
        if (status === 200) {
          this.treeList = result;
          this.treeListCache = result;
          const keys = this.getFirstExpandedKey(result[0]);
          this.expandedKeys = keys;
          this.loading = false;
        }
      });
    },
    getFirstExpandedKey(tree, keys = []) {
      keys.push(tree.sequenceNbr);
      if (tree?.childrenList && tree?.childrenList.length) {
        return this.getFirstExpandedKey(tree?.childrenList[0], keys);
      }
      return keys;
    },
  },
});
</script>

<style lang="less" scoped>
.search {
  padding: 0 15px;
}
.add-inventory {
  min-height: 230px;
}
.tree-item {
  padding-right: 15px;
  position: relative;
  .selected {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 2;
  }
  &:hover {
    .selected {
      display: block;
    }
  }
}
.unit-radio {
  position: absolute;
  top: 0;
  right: -120px;
  z-index: 22;
  padding: 15px;
  box-shadow: 0 0 15px -6px rgba(0, 0, 0, 0.4);
  width: 120px;
  background: #fff;
  .title {
    color: #333;
  }
}
</style>
<style lang="less">
.unit-tooltip {
  .ant-tooltip-inner,
  .ant-tooltip-arrow::before {
    background: #fff;
  }
  .ant-radio-wrapper {
    color: #333;
  }
}
.inventory-tree-loading {
  display: block;
  img {
    margin: 55px auto 0;
  }
}
.unit-radio {
  .ant-radio-group,
  .ant-radio-wrapper {
    display: block;
  }
}
.inventory-tree {
  & > li {
    & > .ant-tree-node-content-wrapper {
      width: calc(100% - 12px);
      background: #fff !important;
    }
  }
  &.ant-tree li {
    .ant-tree-node-content-wrapper {
      width: calc(100% - 24px);
      padding: 0;
      color: #333;
    }
    span.ant-tree-switcher {
      &.ant-tree-switcher_close {
        .ant-tree-switcher-icon {
          transform: scale(1);
        }
      }
      &.ant-tree-switcher_open {
        .ant-tree-switcher-icon {
          transform: scale(1);
        }
      }
    }
  }
  .selected {
    display: none;
    float: right;
    color: #1890ff;
  }
}
</style>
