


















import Vue from 'vue';
export default Vue.extend({
  name: 'SideTree',
  props: {
    replaceFields: {
      type: Object,
      default: () => {
        return {
          key: 'levelCode',
          title: 'classLevel',
          children: 'child',
        };
      },
    },
    selectedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    treeData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    select(selectedKeys, otherParams) {
      this.$emit('update:selectedKeys', selectedKeys);
      this.$emit('select', selectedKeys, otherParams);
    },
  },
});
