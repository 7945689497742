

























































































































import Vue from 'vue';
import type { PropType } from 'vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import { MaterialType, KindType } from '@/interface/materialMachineLibrary';
import materialMachineTable from '@/views/enterpriseLibrary/materialMachineLibrary/component/materialMachineTable.vue';
import priceCompositionTable from '@/views/enterpriseLibrary/materialMachineLibrary/component/priceCompositionTable.vue';
import AddMaterialMachine from '@/views/enterpriseLibrary/materialMachineLibrary/component/AddMaterialMachine.vue';
import TitleInfo from '../../materialMachineLibrary/component/TitleInfo.vue';
import _ from 'lodash';
const componentMap = new Map([
  ['materialMachineTable', '单价构成'],
  ['priceCompositionTable', '消耗量'],
]);
const actionList = [
  {
    type: TableActionType.DELETE,
    name: '删除',
  },
];
const materialColumns = [
  {
    title: '名称',
    key: 'materialName',
  },
  {
    title: '规格型号',
    key: 'specification',
  },
  {
    key: 'kind',
  },
  {
    title: '计量单位',
    key: 'unit',
  },
  {
    title: '消耗量',
    key: 'resQty',
  },
  {
    title: '合计数量',
    key: 'sumNub',
  },
  {
    title: '定额价',
    key: 'dePrice',
  },
  {
    title: '市场价',
    key: 'marketPrice',
  },
  {
    title: '除税系数(%)',
    key: 'taxRemoval',
  },
  {
    title: '除税市场价',
    key: 'csMarketPrice',
  },
];
export default Vue.extend({
  name: 'quotaDetail',
  props: {
    adsQycbRcjModelList: {
      type: Array as PropType<MaterialType[]>,
      default: () => {
        return [];
      },
    },
    qyDePriceConstituteList: {
      type: Array as PropType<MaterialType[]>,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isDataChange: false, // 数据是否有变更
      isEdit: false,
      actionList,
      addDetailVisible: false,
      deleteVisible: false,
      componentName: 'materialMachineTable',
      tableData: [] as MaterialType[],
      currentIndex: 0,
      materialColumns: Object.freeze(materialColumns),
    };
  },
  watch: {
    adsQycbRcjModelList: {
      handler: function () {
        if (this.componentName === 'priceCompositionTable') return;
        this.tableData = this.adsQycbRcjModelList;
      },
      deep: true,
      immediate: true,
    },
    isEdit(val) {
      if (val) this.isDataChange = true;
    },
  },
  computed: {
    deleteTipText(): string {
      return this.tableData.length > 1
        ? '是否删除已选择的人材机明细？'
        : '请保留至少一条人材机明细并且金额大于0';
    },
  },
  methods: {
    sortUpdate(list) {
      this.tableData = list;
    },
    btnTwinkle() {
      const applyBtn = (this.$refs.applyBtn as Vue).$el;
      applyBtn.classList.add('twinkle');
      setTimeout(() => {
        applyBtn.classList.remove('twinkle');
      }, 3000);
    },
    updateData({ record, index }) {
      this.tableData.splice(index, 1, record);
    },
    applyTipHandler() {
      if (this.isDataChange) {
        this.btnTwinkle();
      }
      return this.isDataChange;
    },
    /**
     * 是否材料\主材
     */
    isMaterial(kind) {
      return [KindType.C, KindType.Z].includes(kind);
    },
    /**
     * 应用
     */
    apply() {
      for (let item of this.tableData) {
        if (item.isError) {
          this.$message.warning('当前存在错误值请修改后再应用');
          return;
        }
      }
      this.isEdit = false;
      this.isDataChange = false;
      this.$emit('apply');
    },
    tableChange(e) {
      const value = e.target.value;
      if (this.applyTipHandler()) {
        this.componentName =
          value === 'materialMachineTable'
            ? 'priceCompositionTable'
            : 'materialMachineTable';
        return;
      }
      if (this.componentName === 'materialMachineTable') {
        this.actionList = actionList;
        this.tableData = this.adsQycbRcjModelList;
      } else {
        this.actionList = [];
        this.tableData = this.qyDePriceConstituteList;
      }
    },
    selectMaterial(record) {
      let data = _.cloneDeep(record);
      Object.assign(data, {
        resQty: 0,
        isError: 1,
        initMarketPrice: data.marketPrice,
        sequenceNbr: new Date().getTime().toString(10),
        customCellClass: {
          resQty: 'fail',
        },
      });
      this.tableData.splice(0, 0, data);
      this.isEdit = true;
    },
    actionHandler({ record, key, index }) {
      if (key === TableActionType.DELETE) {
        this.deleteVisible = true;
        this.currentIndex = index;
      }
    },
    deleteData(record, index) {
      this.deleteVisible = true;
      this.currentIndex = index;
    },
    addDetail() {
      this.addDetailVisible = true;
    },
    deleteHandler() {
      this.deleteVisible = false;
      if (this.tableData.length <= 1) return;
      this.tableData.splice(this.currentIndex, 1);
      this.isDataChange = true;
    },
  },
  components: {
    materialMachineTable,
    priceCompositionTable,
    AddMaterialMachine,
    TitleInfo,
  },
});
