
























import Vue from 'vue';
import BaseTable from '../../costQuotaLibrary/components/baseTable.vue';
const columns = [
  {
    title: '序号',
    key: 'sequenceNbr',
  },
  {
    title: '名称',
    key: 'name',
  },
  {
    title: '计算基数',
    key: 'mathBase',
  },
  {
    title: '费率(%)',
    key: 'rate',
  },
  {
    title: '金额(元)',
    key: 'money',
  },
  {
    title: '备注',
    key: 'comment',
  },
];
export default Vue.extend({
  name: 'priceCompositionTable',
  data() {
    return {
      columns: Object.freeze(columns),
    };
  },
  methods: {},
  components: {
    BaseTable,
  },
});
