






















































































import Vue from 'vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import baseTable from './materialMachineTable.vue';
import SideTree from './SideTree.vue';
import EditForm from './EditForm.vue';
import {
  stlMaterialClassLevel,
  getAdsQycbRcj,
  updateQyStlRcj,
} from '@/api/materialMachineLibrary/index';
import { TreeType, MaterialType } from '@/interface/materialMachineLibrary';
const columns = [
  {
    title: '材机名称',
    key: 'materialName',
  },
  {
    title: '规格型号',
    key: 'specification',
  },
  {
    title: '类别',
    key: 'kind',
  },
  {
    title: '计量单位',
    key: 'unit',
  },
  {
    title: '定额价',
    key: 'dePrice',
  },
  {
    title: '除税系数(%)',
    key: 'taxRemoval',
  },
  {
    title: '除税企业价',
    key: '3',
  },
  // {
  //   title: '地区',
  //   key: 'area',
  // },
  // {
  //   title: '价格年月',
  //   key: 'priceDate',
  // },
];
export default Vue.extend({
  name: 'AddMaterialMachine',
  props: {
    title: {
      type: String,
      default: '新增材机',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    conditionKind: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      columns,
      selectedKeys: [],
      editVisible: false,
      selectRecord: {},
      treeData: [] as TreeType[],
      tableData: [] as MaterialType[],
      searchForm: {
        materialName: '',
        specification: '',
        levelCode: '',
        levelType: '',
        page: 1,
        limit: 10,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.getTreeData();
      }
    },
  },
  methods: {
    getTreeData() {
      stlMaterialClassLevel().then(({ status, result }) => {
        if (status === 200) {
          this.treeData = result;
        }
      });
    },
    getListData(params = {}) {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      getAdsQycbRcj(Object.assign(searchForm, params)).then(
        ({ status, result: { list, total } }) => {
          if (status === 200) {
            this.tableData = list;
            this.pagination.total = total;
          }
        }
      );
    },
    formSearch() {
      this.getListData();
    },
    onSearch(value) {
      if (value === '') {
        this.getTreeData();
        return;
      }
      this.treeData = this.filterTreeList(this.treeData, value);
    },
    filterTreeList(list, value = '') {
      const projectTree = JSON.parse(JSON.stringify(list));
      function treeLoop(treeData) {
        let children = [] as TreeType[];
        let isSelect = false;
        for (let index in treeData) {
          const tree = treeData[index];
          const { classLevel } = tree;
          if (classLevel.indexOf(value) > -1) {
            children.push(tree);
            isSelect = true;
          } else {
            if (tree?.child?.length) {
              let [subChild, isChildSelect] = treeLoop(tree.child);
              if (isChildSelect) {
                isSelect = isChildSelect;
                tree.child = subChild;
                children.push(tree);
              }
            }
          }
        }
        return [children, isSelect];
      }
      return treeLoop(projectTree)[0];
    },
    selectType(
      selectedKeys: string[],
      {
        node,
      }: {
        node: { dataRef: Record<string, string | number>; expanded: boolean };
      }
    ) {
      const { levelCode, levelType } = node.dataRef;
      Object.assign(this.searchForm, { levelCode, levelType });
      this.pagination.current = 1;
      this.getListData();
    },
    cancel() {
      this.$emit('update:visible', false);
      this.reset();
    },
    selectHandler(record) {
      if (!this.isEdit) {
        this.$emit('select', record);
        this.cancel();
        return;
      }
      this.selectRecord = record;
      this.editVisible = true;
    },
    editSave(formData) {
      const { username, agencyCode } = this.$store.getters.userInfo;
      formData.sequenceNbr = formData.id;
      formData.extend1 = username;
      formData.agencyCode = agencyCode;
      updateQyStlRcj(formData).then(({ status, result }) => {
        if (status === 200 && result) {
          this.$message.success('新增成功');
          this.$emit('save');
          this.editVisible = false;
          this.cancel();
        }
      });
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
      };
      this.getListData({ page: current, limit: pageSize });
    },
    reset() {
      this.searchForm = {
        materialName: '',
        specification: '',
        levelCode: '',
        levelType: '',
        page: 1,
        limit: 10,
      };
      this.selectedKeys = [];
      this.tableData = [];
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getTreeData();
    },
    isDisabled(kind) {
      if (!this.conditionKind.length) {
        return false;
      }
      return !this.conditionKind.includes(kind);
    },
  },
  components: {
    SideTree,
    baseTable,
    EditForm,
  },
});
