var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quota-detail"},[_c('TitleInfo',{attrs:{"title":"定额详细","titleStyle":{ color: '#1890ff' }}},[(_vm.adsQycbRcjModelList && _vm.adsQycbRcjModelList.length)?_c('div',{staticClass:"head-action"},[_c('a-radio-group',{staticStyle:{"margin-right":"15px"},attrs:{"button-style":"solid"},on:{"change":_vm.tableChange},model:{value:(_vm.componentName),callback:function ($$v) {_vm.componentName=$$v},expression:"componentName"}},[_c('a-radio-button',{attrs:{"value":"materialMachineTable"}},[_vm._v(" 消耗量 ")]),_c('a-radio-button',{attrs:{"value":"priceCompositionTable"}},[_vm._v(" 费用构成 ")])],1),_c('a-button',{ref:"applyBtn",attrs:{"type":"primary","disabled":!_vm.isDataChange},on:{"click":_vm.apply}},[_vm._v(" 应用 ")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.componentName !== 'materialMachineTable',"ghost":""},on:{"click":function($event){_vm.isEdit = true}}},[_vm._v(" 编辑明细 ")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.componentName !== 'materialMachineTable',"ghost":""},on:{"click":_vm.addDetail}},[_vm._v(" 添加明细 ")])],1):_vm._e()]),(_vm.adsQycbRcjModelList && _vm.adsQycbRcjModelList.length)?[_c('div',{staticClass:"table-content scrollbar"},[_c(_vm.componentName,{tag:"component",attrs:{"tableData":_vm.tableData,"actionList":_vm.actionList,"columns":_vm.materialColumns,"isEdit":_vm.isEdit,"fieldSlots":[
          'kind',
          'resQty',
          'marketPrice',
          'csMarketPrice',
          'companyPrice' ],"customAction":true,"showPagination":false},on:{"updateData":_vm.updateData,"sortUpdate":_vm.sortUpdate},scopedSlots:_vm._u([(_vm.componentName === 'materialMachineTable')?{key:"customAction",fn:function(ref){
        var record = ref.record;
        var index = ref.index;
return [_c('a-button',{class:['btn delete'],attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.deleteData(record, index)}}},[_vm._v(" 删除 ")])]}}:null],null,true)})],1)]:_c('div',{staticClass:"not-data scrollbar"},[_c('icon-font',{staticClass:"icon",attrs:{"type":"icon-queshengye_zanwushujubeifen"}}),_c('div',{staticClass:"text"},[_vm._v("选择定额后查看对应人材机明细")])],1),_c('add-material-machine',{attrs:{"visible":_vm.addDetailVisible,"isEdit":false,"conditionKind":[2, 5],"title":"材料明细"},on:{"update:visible":function($event){_vm.addDetailVisible=$event},"select":_vm.selectMaterial}}),_c('a-modal',{staticClass:"common-dialog custom-common-dialog",attrs:{"visible":_vm.deleteVisible,"footer":false},on:{"cancel":function($event){_vm.deleteVisible = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"custom-title"},[_c('div',{staticClass:"title"},[_vm._v("明细删除提示")])])]},proxy:true}])},[_c('div',{staticClass:"tip-text"},[_vm._v(_vm._s(_vm.deleteTipText))]),_c('div',{staticClass:"ant-modal-footer"},[_c('a-button',{on:{"click":function($event){_vm.deleteVisible = false}}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.deleteHandler}},[_vm._v(" 确定 ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }